import WorldImage from "../../../images/world-img-blue-new-webp.webp";
import { Link } from "react-router-dom";

const New404Page = () => {
  return (
    <>
      <section className="banner-section banner-main w-full bg-black_text backdrop-blur-[87.5px] relative overflow-hidden">
        <div className="banner-elements-right absolute -z-[1] top-0 -right-[200px] blur-[87.5px] w-[480px] h-[480px] rounded-full opacity-90 bg-[#16FCD2] before:content-[''] before:bg-black_text/90 before:z-[1] before:absolute before:w-[500px] before:h-[500px] before:rounded-full before:blur-[80px]"></div>
        <div className="banner-elements-left absolute -z-[1] top-[120px] left-[89px] blur-[87.5px] w-[300px] h-[300px] rounded-full opacity-90 bg-[#16FCD2] before:content-[''] before:bg-black_text/90 before:z-[1] before:absolute before:w-[300px] before:h-[300px] before:rounded-full before:blur-[80px]"></div>

        <div className="banner-elements-bottom absolute -z-[1] -bottom-[210px] right-[300px] blur-[87.5px] w-[590px] h-[590px] rounded-full opacity-90 bg-[#4910bf]/50 before:content-[''] before:bg-black_text/90 before:z-[1] before:absolute before:w-[600px] before:h-[600px] before:rounded-full before:blur-[160px]"></div>

        <div className="world-img-left absolute w-[400px] h-[400px] xl:w-[600px] xl:h-[600px] -top-[20px] -left-[250px] sm:-left-[200px] -z-[1]">
          <img loading="lazy" src={WorldImage} alt="Our World" />
        </div>
        <div className="container mx-auto">
          <div className="main-content max-w-[1200px] mx-auto flex flex-col items-center justify-center text-center h-screen">
            <div className="top-title text-primary/70 sm:text-primary/50 md:text-lg text-[14px] tracking-[1.6px] mb-7">
              myBox.social
            </div>
            <h1 className="font-bold text-golden_text text-4xl sm:text-5xl lg:text-6xl xl:text-[80px]  !leading-tight">
              <span className="block text-8xl sm:text-[100px] md:text-[140px] lg:text-[160px] xl:text-[180px]">
                OMG!
              </span>
              <span className="block">You're totally off the planet</span>
            </h1>
            <div className="mt-6 text-base font-normal sm:text-xl md:font-light lg:font-extralight text-white/70">
              <p className="mt-0">Whatever you clicked on was bad. Real bad.</p>
              <p className="">You're in the black hole of cyberspace.</p>
              <p className="mt-6">Don't panic! Breathe in & brace yourself. </p>
              <p className="mb-20">Let's bring you down to Earth!</p>
            </div>
            <div className="tabs-main flex items-center gap-2 sm:gap-5 lg:gap-12 justify-center">
              <Link
                to="/"
                className="px-8 py-3 sm:py-3 lg:px-16 lg:py-5 whitespace-nowrap flex justify-center items-center text-lg text-white bg-white/20 rounded-full border border-white/20 hover:bg-white hover:text-black_text transition-all hover:transition-all cursor-pointer"
              >
                Back to myBox!
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default New404Page;
