import React, { Suspense } from "react";

export const Loadable = (Component: any) => {
    const Modified = (props: any) => (
      <Suspense fallback={<> </>}>
        <Component {...props} />
      </Suspense>
    );
    Modified.displayName = "Modified";
    return Modified;
  };

 